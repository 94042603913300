import React from 'react'
import styled from 'styled-components';

import Button from '../../_atoms/Button';
import CTANav from '../../_molecules/CTANav';
import Hero from '../../_molecules/Hero';

const Wrap = styled.div`
	display: flex;
	background-color: black;
	width: 100%;

	sub {
		bottom: 0;
	}
`;

function HomeTop() {
	return (
		<Wrap>
			<CTANav />
			<Hero
				bg='portal-masthead-2023-AA.jpg'
				bgPreview='compressed-portal-masthead-2023-AA.jpg'
				size='lg'
				className='ram2022'
			>
				<h1>
					NEW OFFERINGS. <span>NEW OPPORTUNITIES.</span>
				</h1>
				<footer>
					<Button
						target='_blank'
						href='https://firebasestorage.googleapis.com/v0/b/mopar-portal-production.appspot.com/o/assets%2Fawj7TewKp0CDIa95mmZV%2Ffile-forPreview?alt=media&token=b7ec17bc-d408-4baa-9ee0-9d39b7910b09'
						el='a'
						variant='alternate'
					>
						More Info
					</Button>
					{/* access guide */}
					<Button
						target='_blank'
						href='https://firebasestorage.googleapis.com/v0/b/mopar-portal-production.appspot.com/o/assets%2FIimGuzkunA52Ba9awFw0%2Ffile-forPreview?alt=media&token=5eb97c61-3c4d-4758-b9ce-73def8cb5f4a'
						el='a'
						variant='alternate'
					>
						Access Guide
					</Button>
				</footer>
			</Hero>
		</Wrap>
	);
}

export default HomeTop;
